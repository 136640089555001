import React from "react";
import { useState } from "react";

const RatingPlanner = ({ name, icon }) => {
  const [select, setSelect] = useState();
  return (
    <div className=" overflow-hidden text-[14px] h-fit lg:text-[16px] text-[#333] rounded-md font-bold border border-[#999]">
      <div className="p-2 border-b border-[#999] bg-[#93d4e2]">
        {" "}
        {icon} {name}
      </div>
      <div dir="ltr" className="p-2 bg-white gap-1 grid grid-cols-10">
        {Array(10)
          .fill("")
          .map((e, i) => (
            <span
              onClick={() => setSelect(i + 1)}
              className={`${
                select === i + 1 ? "border border-red-500" : ""
              } text-center cursor-pointer py-1  rounded-full`}
            >
              {i + 1}
            </span>
          ))}
        <div className="col-span-full bg-gradient-to-r from-slate-300 via-75% to-red-500 via-green-500 p-1 rounded-lg mt-2"></div>
      </div>
    </div>
  );
};

export default RatingPlanner;
