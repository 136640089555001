import React, { useState } from "react";

const DayPlannerBox = ({ name }) => {
  const [day1, setDay1] = useState([]);
  const [text, settext] = useState("");

  return (
    <div className=" overflow-hidden text-[14px] lg:text-[16px] text-[#333] rounded-md font-bold border border-[#999]">
      <div className="p-2 border-b border-[#999] bg-[#d9db9a]">{name}</div>
      <div className="p-2 bg-white ">
        {day1?.map((e) => {
          return (
            <div className="flex items-center justify-between gap-1 my-2">
              <span className="flex gap-2 items-center">
                <input
                  onClick={() => {
                    let updatedList = day1.map((item) => {
                      if (e.value == item.value) {
                        return { ...item, checked: !item.checked }; //gets everything that was already in item, and updates "done"
                      }
                      return item; // else return unmodified item
                    });
                    setDay1(updatedList);
                  }}
                  type="checkbox"
                  checked={e.checked}
                />

                {e.value}
              </span>

              <i
                onClick={() => {
                  const newArray = day1.filter((d) => e.value !== d.value);
                  setDay1(newArray);
                }}
                class="fa-solid  fa-trash text-[20px] text-red-500"
              ></i>
            </div>
          );
        })}
        <div className="flex items-center gap-1 my-2">
          <input
            onChange={(e) => settext(e.target.value)}
            value={text}
            placeholder="اضافه کردن"
            className="input-text input-text--primary-style w-full"
          />
          <button
            onClick={() => {
              setDay1((cur) => [...cur, { value: text, checked: false }]);
              settext("");
            }}
            className="rounded-full bg-green-600 w-[24px] h-[24px] flex justify-center items-center"
          >
            <i class="fa-solid  fa-check "></i>{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DayPlannerBox;
