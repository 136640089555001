import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Reveal from "../components/motion/Reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../utils/httprequest";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const Signup = () => {
  const [step2, setStep2] = useState(false);
  const [acceptCode, setacceptCode] = useState();
  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      l_name: Yup.string().required("الزامی است"),
      f_name: Yup.string().required("الزامی است"),
      password: Yup.string().required("الزامی است"),
      phone: Yup.string()
        .min(11, "شماره موبایل صحیح نیست")
        .max(11, "شماره موبایل صحیح نیست")
        .required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: { f_name: "", l_name: "", phone: "", password: "" },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      await httpReauest("POST", "/user/signup", values, {})
        .then((data) => {
          if (data?.status == 201) {
            toast.success("اطلاعات با موفقیت ثبت شد");
            setStep2(true);
          }
        })
        .catch((data) => {
          toast.error(data?.response?.data?.message);
          formik.resetForm();
        });
    },
  });

  async function handleAccept(e) {
    e.preventDefault();
    await httpReauest(
      "POST",
      "/user/signup/approve",
      { phone: formik.values.phone, acceptCode },
      {}
    )
      .then((dataa) => {
        if (dataa?.status == 201) {
          toast.success("کد صحیح بود");
          localStorage.setItem("user", JSON.stringify(dataa.data?.data));
          window.location.href = window.origin + "/dashboard";
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
        formik.resetForm();
      });
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user?.token) {
      window.location.href = window.origin + "/dashboard";
    }
  }, []);

  return (
    <>
      <Header />
      <div style={{ background: "#eee" }} class="app-content">
        <div class="u-s-p-y-60">
          <div class="section__intro u-s-m-b-60">
            <div class="container">
              <div class="row row--center">
                <Reveal>
                  <div class="col-lg-12">
                    <div class="section__text-wrap">
                      <h1 class="section__heading u-c-secondary">ثبت نام</h1>
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>

          <div class="section__content">
            <div class="container">
              <div class="row row--center">
                <div class="col-lg-6 col-md-8 u-s-m-b-30">
                  <Reveal head>
                    <div
                      style={{ background: "#fff", borderRadius: "25px" }}
                      class="l-f-o"
                    >
                      {step2 ? (
                        <div class="l-f-o__pad-box">
                          <h1 class="gl-h1">تایید شماره موبایل</h1>
                          <form class="l-f-o__form">
                            <div class="u-s-m-b-30">
                              <label class="gl-label" for="acceptCode">
                                عددی که فرستادیم در اینجا وارد کنید
                              </label>

                              <input
                                class="input-text input-text--primary-style"
                                type="number"
                                id="acceptCode"
                                name="acceptCode"
                                value={acceptCode}
                                onChange={(e) => setacceptCode(e.target.value)}
                              />
                            </div>
                            <div class="u-s-m-b-15">
                              <button
                                class="btn btn--e-transparent-brand-b-2"
                                onClick={handleAccept}
                              >
                                تایید کد
                              </button>
                            </div>
                          </form>
                        </div>
                      ) : (
                        <div class="l-f-o__pad-box">
                          <h1 class="gl-h1">اطلاعات خود را وارد کنید</h1>
                          <form
                            onSubmit={formik.handleSubmit}
                            class="l-f-o__form"
                          >
                            <div class="u-s-m-b-30">
                              <label class="gl-label" for="f_name">
                                نام *
                              </label>

                              <input
                                class="input-text input-text--primary-style"
                                type="text"
                                id="f_name"
                                name="f_name"
                                value={formik.values.f_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="نام"
                              />
                              {formik.errors.f_name &&
                                formik.touched.f_name && (
                                  <small
                                    className="text-danger"
                                    style={{ color: "red" }}
                                  >
                                    {formik.errors.f_name}
                                  </small>
                                )}
                            </div>
                            <div class="u-s-m-b-30">
                              <label class="gl-label" for="l_name">
                                نام خانوادگی *
                              </label>

                              <input
                                class="input-text input-text--primary-style"
                                type="text"
                                id="l_name"
                                name="l_name"
                                value={formik.values.l_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="نام خانوادگی"
                              />
                              {formik.errors.l_name &&
                                formik.touched.l_name && (
                                  <small
                                    className="text-danger"
                                    style={{ color: "red" }}
                                  >
                                    {formik.errors.l_name}
                                  </small>
                                )}
                            </div>
                            <div class="u-s-m-b-30">
                              <label class="gl-label" for="phone">
                                شماره موبایل *
                              </label>

                              <input
                                class="input-text input-text--primary-style"
                                type="text"
                                id="phone"
                                name="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="شماره موبایل"
                              />
                              {formik.errors.phone && formik.touched.phone && (
                                <small
                                  className="text-danger"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.phone}
                                </small>
                              )}
                            </div>
                            <div class="u-s-m-b-30">
                              <label class="gl-label" for="password">
                                رمز *
                              </label>

                              <input
                                class="input-text input-text--primary-style"
                                type="password"
                                id="password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="رمز"
                              />
                              {formik.errors.password &&
                                formik.touched.password && (
                                  <small
                                    className="text-danger"
                                    style={{ color: "red" }}
                                  >
                                    {formik.errors.password}
                                  </small>
                                )}
                            </div>
                            <div class="u-s-m-b-15">
                              <button
                                class="btn btn--e-transparent-brand-b-2"
                                type="submit"
                              >
                                ارسال
                              </button>
                            </div>
                            <Link class="gl-link" to="/signin">
                              قبلا ثبت نام کردی؟
                            </Link>
                          </form>
                        </div>
                      )}
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Signup;
