import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Reveal from "../motion/Reveal";

const Slider = () => {
  return (
    <div class="s-skeleton s-skeleton--h-600 s-skeleton--bg-grey">
      <div class=" primary-style-1" id="hero-slider">
        <Swiper
          spaceBetween={50}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          loop
          autoplay={{ delay: 4000 }}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <div className="flex h-[550px] xl:h-[650px] relative">
            <img className="w-full object-cover" src="/images/slider/slide-1.jpg" alt="Slider"/>
              {/* <video
                src="/bg/1.mp4"
                autoPlay
                muted
                loop
                className="col w-full object-cover p-0 m-0 "
              ></video>
              <video
                src="/bg/2.mp4"
                autoPlay
                muted
                loop
                className="col w-full object-cover p-0 m-0 hidden sm:block"
              ></video>
              <video
                src="/bg/3.mp4"
                autoPlay
                muted
                loop
                className="col w-full object-cover p-0 m-0 hidden lg:block"
              ></video>
              <video
                src="/bg/4.mp4"
                autoPlay
                muted
                loop
                className="col w-full object-cover p-0 m-0 hidden xl:block"
              ></video>
              <video
                src="/bg/5.mp4"
                autoPlay
                muted
                loop
                className="col w-full object-cover p-0 m-0 hidden 2xl:block"
              ></video> */}
              <div className="bg_0"></div>
              <div class="hero-slide hero-slide--1  absolute">
                <div class="container">
                  <div class="row px-2">
                    <div class="w-full">
                      <div class="slider-content slider-content--animation px-6 lg:px-14 bg-[#00000080] w-fit mx-auto pb-8 pt-3 rounded-xl">
                        <Reveal head width={"fit-content"}>
                          <span
                            style={{ textShadow: "3px 3px 2px var(--brand)" }}
                            class="content-span-2  u-c-white  text-center"
                          >
                            به قدرت خود پی ببرید!
                          </span>
                        </Reveal>
                        <Reveal head width={"fit-content"}>
                          <span class="content-span-3  u-c-white text-center">
                            "من قوی"، بستری برای ارتقاء توانمندی‌ها و پیشرفت
                            شخصی و حرفه‌ای. <br /> همراه شوید، به قدرت خود پی
                            ببرید!
                          </span>
                        </Reveal>
                        <br />
                        <div className="row justify-center">
                          <Reveal head width={"fit-content"}>
                            <Link
                              className="shop-now-link btn--e-brand"
                              to="/signup"
                            >
                              ثبت نام
                            </Link>
                          </Reveal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Slider;
