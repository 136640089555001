import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../motion/Reveal";
import { SrviceInfo } from "../../pages/ServiceDetails";
import { CDN_BASE_URL } from "../../config";
import { CartContext } from "../../App";

const Header = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [allPrice, setallPrice] = useState(0);

  const { cart, setCart } = useContext(CartContext);

  useEffect(() => {
    renderData();
  }, [cart]);

  function renderData() {
    let n = 0;
    cart?.map((e) => {
      n = n + e.price * e.count;
    });
    setallPrice(n);
  }

  function handleDelete(id) {
    const newCart = cart.filter((e) => e._id != id);
    setCart(newCart);
  }

  return (
    <header class="header--style-1">
      <Reveal head>
        <nav class="primary-nav primary-nav-wrapper--border">
          <div class="container">
            <div class="primary-nav">
              <a
                class="main-logo font-mono text-[35px] text-black font-extrabold"
                href="/"
              >
                <img
                  className="w-[80px]"
                  src="/images/logo/logo-1.png"
                  alt=""
                />
              </a>

              <div class="ah-lg-mode">
                <span class="ah-close">✕ Close</span>

                <ul class="ah-list ah-list--design2 ah-list--link-color-secondary">
                  <li>
                    <Link to="/">خانه</Link>
                  </li>
                  <li class="has-dropdown">
                    <a>
                      خدمات<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                    </a>

                    <span class="js-menu-toggle"></span>
                    <ul style={{ width: "200px" }}>
                      {SrviceInfo.map((e) => {
                        return (
                          <li>
                            <Link to={"/service/" + e.id}>{e.title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>

                  <li class="has-dropdown">
                    <a>
                      روادید<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                    </a>

                    <span class="js-menu-toggle"></span>
                    <ul style={{ width: "200px" }}>
                      <li>
                        <Link to="/service/branding">سمینار</Link>
                      </li>
                      <li>
                        <Link to="/service/branding">تور</Link>
                      </li>
                    </ul>
                  </li>
                  <li class="has-dropdown">
                    <a>
                      محصولات<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                    </a>

                    <span class="js-menu-toggle"></span>
                    <ul style={{ width: "200px" }}>
                      <li>
                        <Link to="/products">استایل</Link>
                      </li>
                      <li>
                        <Link to="/products">اکسسوری</Link>
                      </li>
                      <li>
                        <Link to="/products">گیفت</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/about">درباره ما</Link>
                  </li>
                  <li>
                    <Link to="/service/branding">ارتباط با ما</Link>
                  </li>
                </ul>
              </div>

              <div class="menu-init" id="navigation">
                <Link
                  className="lg:hidden"
                  style={{ marginLeft: "42px" }}
                  to="/cart"
                >
                  <i class="fa-solid fa-cart-shopping"></i>
                  <span class="total-item-round">{cart?.length}</span>
                </Link>
                <button
                  class="btn btn--icon text-black toggle-button toggle-button--secondary fa-solid fa-align-justify"
                  type="button"
                  onClick={() => setShow(true)}
                ></button>

                <div
                  className={`${
                    !show && "left-[-100%]"
                  } fixed left-0 top-0 h-full w-[250px] bg-white z-10 duration-500`}
                >
                  <div className="w-full border-b p-1">
                    <span onClick={() => setShow(false)} class="ah-close">
                      ✕
                    </span>
                  </div>
                  <div className="w-full">
                    <ul class="ah-list ah-list--design2 ah-list--link-color-secondary w-full">
                      <li>
                        <Link to="/">خانه</Link>
                      </li>
                      <li>
                        <Link to="/signup">ثبت نام</Link>
                      </li>
                      <li>
                        <Link to="/signin">ورود</Link>
                      </li>
                      <li class="has-dropdown">
                        <a>
                          خدمات<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                        </a>
                        <span
                          onClick={() => setShow2(!show2)}
                          class="js-menu-toggle"
                        ></span>
                        {show2 && (
                          <ul style={{ width: "200px" }}>
                            {SrviceInfo.map((e) => {
                              return (
                                <li>
                                  <Link to={"/service/" + e.id}>{e.title}</Link>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>

                      <li class="has-dropdown">
                        <a>
                          روادید<i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                        </a>

                        <span
                          class="js-menu-toggle"
                          onClick={() => setShow3(!show3)}
                        ></span>
                        {show3 && (
                          <ul style={{ width: "200px" }}>
                            <li>
                              <Link to="/service/branding">سمینار</Link>
                            </li>
                            <li>
                              <Link to="/service/branding">تور</Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li class="has-dropdown">
                        <a>
                          محصولات
                          <i class="fa-solid fa-angle-down u-s-m-r-6"></i>
                        </a>
                        <span
                          onClick={() => setShow4(!show4)}
                          class="js-menu-toggle"
                        ></span>
                        {show4 && (
                          <ul style={{ width: "200px" }}>
                            <li>
                              <Link to="/products">استایل</Link>
                            </li>
                            <li>
                              <Link to="/products">اکسسوری</Link>
                            </li>
                            <li>
                              <Link to="/products">گیفت</Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li>
                        <Link to="/service/branding">درباره ما</Link>
                      </li>
                      <li>
                        <Link to="/service/branding">ارتباط با ما</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="ah-lg-mode">
                  <span class="ah-close">✕ Close</span>

                  <ul class="ah-list ah-list--design1 ah-list--link-color-secondary">
                    <li
                      class="has-dropdown"
                      data-tooltip="tooltip"
                      data-placement="left"
                      title="Account"
                    >
                      <Link to="/cart">
                        <i class="fa-solid fa-cart-shopping"></i>
                        <span class="total-item-round">{cart?.length}</span>
                      </Link>

                      <span class="js-menu-toggle"></span>
                      <div class="mini-cart">
                        <div class="mini-product-container gl-scroll u-s-m-b-15">
                          {cart?.length ? (
                            cart?.map((p) => {
                              return (
                                <div class="card-mini-product">
                                  <div class="mini-product">
                                    <div class="mini-product__image-wrapper">
                                      <a
                                        class="mini-product__link"
                                        href="product-detail.html"
                                      >
                                        <img
                                          class="u-img-fluid"
                                          src={CDN_BASE_URL + p.img}
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                    <div class="mini-product__info-wrapper">
                                      <span class="mini-product__category">
                                        <a href="shop-side-version-2.html">
                                          {p?.category?.name}
                                        </a>
                                      </span>

                                      <span class="mini-product__name">
                                        <a href="product-detail.html">
                                          {p.name}
                                        </a>
                                      </span>

                                      <span class="mini-product__price">
                                        {p.price} ريال
                                      </span>
                                      <span class="mini-product__quantity">
                                        {p.count}x
                                      </span>
                                    </div>
                                  </div>

                                  <a
                                    onClick={() => handleDelete(p._id)}
                                    class="mini-product__delete-link fa-regular fa-trash-alt"
                                  ></a>
                                </div>
                              );
                            })
                          ) : (
                            <h3 className="text-center text-[25px] text-black">
                              سبد خالی است
                            </h3>
                          )}
                        </div>

                        <div class="mini-product-stat">
                          <div class="mini-total">
                            <span class="subtotal-text">مجموع</span>

                            <span class="subtotal-value">
                              {allPrice.toLocaleString()}ريال
                            </span>
                          </div>
                          <div class="mini-action">
                            <Link
                              class="mini-link btn--e-transparent-secondary-b-2"
                              to="/cart"
                            >
                              مشاهده سبد خرید
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li
                      class="has-dropdown"
                      data-tooltip="tooltip"
                      data-placement="left"
                      title="Account"
                    >
                      <a>
                        <i class="fa-solid fa-user-circle"></i>
                      </a>

                      <span class="js-menu-toggle"></span>
                      <ul style={{ width: "120px" }}>
                        <li>
                          <Link to="/dashboard">
                            <i class="fa-solid fa-user-circle u-s-m-l-6"></i>

                            <span>پروفایل</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/signup">
                            <i class="fa-solid fa-user-plus u-s-m-l-6"></i>

                            <span>ثبت نام</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/signin">
                            <i class="fa-solid fa-lock u-s-m-l-6"></i>

                            <span>ورود</span>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li
                      data-tooltip="tooltip"
                      data-placement="left"
                      title="Contact"
                    >
                      <a href="tel:+0900901904">
                        <i class="fa-solid fa-phone-volume"></i>
                      </a>
                    </li>
                    <li
                      data-tooltip="tooltip"
                      data-placement="left"
                      title="Mail"
                    >
                      <a href="mailto:contact@domain.com">
                        <i class="fa-solid fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </Reveal>
    </header>
  );
};

export default Header;
