import React, { useEffect, useState } from "react";
import Filter from "../components/common/Filter";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import ProductCard from "../components/common/ProductCard";
import Reveal from "../components/motion/Reveal";
import { httpReauest } from "../utils/httprequest";
import { useLocation } from "react-router-dom";
import Loading from "../components/common/Loading";

const Products = () => {
  const [data, setData] = useState();
  const [category, setcategory] = useState();
  const [loading, setloading] = useState(false);
  const [selected, setselected] = useState(false);
  const [show, setshow] = useState(false);
  const [categoryQuery, setCcategoryQuery] = useState({
    category: "",
    type: "",
    min_price: "",
    max_price: "",
    discounted: "",
    balance: "",
  });
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const location = useLocation();

  useEffect(() => {
    const c = query.get("category");
    const mip = query.get("min_price");
    const mxp = query.get("max_price");
    const b = query.get("balance");
    const d = query.get("discounted");

    setCcategoryQuery({
      category: c,
      min_price: mip,
      max_price: mxp,
      balance: b,
      discounted: d,
    });
    getData({
      category: c ? c : "",
      min_price: mip ? mip : "",
      max_price: mxp ? mxp : "",
      balance: b ? b : "",
      discounted: d ? d : "",
    });
  }, [location]);

  useEffect(() => {
    const c = query.get("category");
    const mip = query.get("min_price");
    const mxp = query.get("max_price");
    const b = query.get("balance");
    const d = query.get("discounted");
    getData({
      category: c ? c : "",
      min_price: mip ? mip : "",
      max_price: mxp ? mxp : "",
      balance: b ? b : "",
      discounted: d ? d : "",
    });
    getCategory();
  }, []);

  async function getData({
    category,
    discounted,
    balance,
    min_price,
    max_price,
  }) {
    setloading(true);
    const newData = await httpReauest(
      "GET",
      "/product?category=" +
        category +
        "&discounted=" +
        discounted +
        "&balance=" +
        balance +
        "&min_price=" +
        min_price +
        "&max_price=" +
        max_price,
      {},
      {}
    );

    setData(newData.data.data);
    setloading(false);
  }

  async function getCategory(params) {
    const cate = await httpReauest("GET", "/product/category", {}, {});

    setcategory(cate.data.data);
  }

  return (
    <div>
      <Header />
      <div style={{ backgroundColor: "#eee" }} class="app-content">
        <div class="u-s-p-y-90">
          <div class="container">
            <div class="row">
              <div class="col-lg-9 col-md-12">
                <div class="shop-p">
                  <div class="shop-p__toolbar u-s-m-b-30 px-2">
                    <Reveal>
                      <div class="shop-p__meta-wrap u-s-m-b-60 bg-white">
                        <span class="shop-p__meta-text-1">
                          {data?.length} محصول پیدا شد
                        </span>
                        <div class="shop-p__meta-text-2">
                          <span className="u-s-m-l-10">موارد سرچ شده:</span>
                          {categoryQuery?.category && (
                            <a class="gl-tag btn--e-brand-shadow" href="#">
                              {selected}
                            </a>
                          )}
                          {categoryQuery.discounted && (
                            <a class="gl-tag btn--e-brand-shadow" href="#">
                              محصولات تخفیف دار
                            </a>
                          )}
                          {categoryQuery.balance && (
                            <a class="gl-tag btn--e-brand-shadow" href="#">
                              محصولات موجود
                            </a>
                          )}
                        </div>
                      </div>
                    </Reveal>
                    <Reveal>
                      <div class="shop-p__tool-style px-2">
                        <h1
                          onClick={() => setshow(true)}
                          class="shop-w-master__heading u-s-m-b-30 lg:hidden"
                        >
                          <i class="fa-solid fa-filter u-s-m-l-8"></i>

                          <span>فیلتر </span>
                        </h1>
                        <form>
                          <div class="tool-style__form-wrap">
                            <div class="u-s-m-b-8">
                              <select class="select-box select-box--transparent-b-2">
                                <option selected>
                                  مرتب شده: جدیدترین محصولات
                                </option>
                                <option>مرتب شده : قدیمی ترین محصولات</option>
                                <option>مرتب شده : قدیمی ترین محصولات</option>
                                <option>مرتب شده : قدیمی ترین محصولات</option>
                                <option>مرتب شده : قدیمی ترین محصولات</option>
                              </select>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Reveal>
                  </div>
                  <div class="shop-p__collection">
                    <div class="row is-grid-active">
                      {!loading ? (
                        data?.map((e) => {
                          return <ProductCard data={e} />;
                        })
                      ) : (
                        <Loading />
                      )}
                    </div>
                  </div>
                  <div class="u-s-p-y-60">
                    <ul class="shop-p__pagination">
                      <li class="is-active">
                        <a href="shop-grid-right.html">1</a>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">2</a>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">3</a>
                      </li>
                      <li>
                        <a href="shop-grid-right.html">4</a>
                      </li>
                      <li>
                        <a
                          class="fa-solid fa-angle-left"
                          href="shop-grid-right.html"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <Filter
                category={category}
                categoryQuery={categoryQuery}
                setselected={setselected}
                show={show}
                setshow={setshow}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Products;
