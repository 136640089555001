import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Link } from "react-router-dom";
import Reveal from "../components/motion/Reveal";
import Reviews from "../components/home/Reviews";

const About = () => {
  return (
    <div>
      <Header />
      <div class="app-content bg-[#eee]">
        <div class="u-s-p-y-60">
          <div class="section__content ">
            <div class="container">
              <div class="row ">
                <div class="col-lg-12 ">
                  <Reveal>
                    <div class="about rounded-[15px]">
                      <div class="about__container ">
                        <div class="about__info">
                          <h2 class="about__h2">من قوی</h2>
                          <div class="about__p-wrap py-4">
                            <p class="about__p">
                              "من قوی"، جایی برای آغاز توانمندی نسل آینده! از
                              اینکه شما یک قهرمان هستید شروع کنید. در "من قوی"،
                              هدف ما تقویت نسل های آینده است. در کنار ما نه تنها
                              توانمندی خود را کشف می‌کنید بلکه به رهبری در زندگی
                              شخصی و حرفه‌ای خود می‌پردازید. به جمع ما بپیوندید
                              و با هم به سوی قله‌های جدید پیش برویم. "من قوی"،
                              جایی برای رشد و توسعه شماست!
                            </p>
                          </div>

                          <Link
                            class="about__link btn--e-secondary"
                            to="/signup"
                          >
                            ثبت نام
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="u-s-p-b-10">
          <div class="section__intro u-s-m-b-46">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section__text-wrap">
                    <Reveal head>
                      <h1 class="section__heading u-c-secondary">
                        اعضای تیم ما
                      </h1>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section__content">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6 u-s-m-b-30">
                  <Reveal>
                    <div class="team-member u-h-100">
                      <div class="team-member__wrap">
                        <div class="aspect aspect--bg-grey-fb aspect--square">
                          <img
                            class="aspect__img team-member__img"
                            src="images/about/member-1.jpg"
                            alt=""
                          />
                        </div>
                        <div class="team-member__social-wrap">
                          <ul class="team-member__social-list">
                            <li>
                              <a class="s-tw--bgcolor-hover" href="#">
                                <i class="fa-brands fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-fb--bgcolor-hover" href="#">
                                <i class="fa-brands fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-insta--bgcolor-hover" href="#">
                                <i class="fa-brands fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-linked--bgcolor-hover" href="#">
                                <i class="fa-brands fa-linkedin"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="team-member__info">
                        <span class="team-member__name">Ahnan Bashri</span>

                        <span class="team-member__job-title">Manager</span>
                      </div>
                    </div>
                  </Reveal>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 u-s-m-b-30">
                  <Reveal>
                    <div class="team-member u-h-100">
                      <div class="team-member__wrap">
                        <div class="aspect aspect--bg-grey-fb aspect--square">
                          <img
                            class="aspect__img team-member__img"
                            src="images/about/member-1.jpg"
                            alt=""
                          />
                        </div>
                        <div class="team-member__social-wrap">
                          <ul class="team-member__social-list">
                            <li>
                              <a class="s-tw--bgcolor-hover" href="#">
                                <i class="fa-brands fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-fb--bgcolor-hover" href="#">
                                <i class="fa-brands fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-insta--bgcolor-hover" href="#">
                                <i class="fa-brands fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-linked--bgcolor-hover" href="#">
                                <i class="fa-brands fa-linkedin"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="team-member__info">
                        <span class="team-member__name">Ahnan Bashri</span>

                        <span class="team-member__job-title">Manager</span>
                      </div>
                    </div>
                  </Reveal>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 u-s-m-b-30">
                  <Reveal>
                    <div class="team-member u-h-100">
                      <div class="team-member__wrap">
                        <div class="aspect aspect--bg-grey-fb aspect--square">
                          <img
                            class="aspect__img team-member__img"
                            src="images/about/member-1.jpg"
                            alt=""
                          />
                        </div>
                        <div class="team-member__social-wrap">
                          <ul class="team-member__social-list">
                            <li>
                              <a class="s-tw--bgcolor-hover" href="#">
                                <i class="fa-brands fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-fb--bgcolor-hover" href="#">
                                <i class="fa-brands fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-insta--bgcolor-hover" href="#">
                                <i class="fa-brands fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-linked--bgcolor-hover" href="#">
                                <i class="fa-brands fa-linkedin"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="team-member__info">
                        <span class="team-member__name">Ahnan Bashri</span>

                        <span class="team-member__job-title">Manager</span>
                      </div>
                    </div>
                  </Reveal>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 u-s-m-b-30">
                  <Reveal>
                    <div class="team-member u-h-100">
                      <div class="team-member__wrap">
                        <div class="aspect aspect--bg-grey-fb aspect--square">
                          <img
                            class="aspect__img team-member__img"
                            src="images/about/member-1.jpg"
                            alt=""
                          />
                        </div>
                        <div class="team-member__social-wrap">
                          <ul class="team-member__social-list">
                            <li>
                              <a class="s-tw--bgcolor-hover" href="#">
                                <i class="fa-brands fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-fb--bgcolor-hover" href="#">
                                <i class="fa-brands fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-insta--bgcolor-hover" href="#">
                                <i class="fa-brands fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a class="s-linked--bgcolor-hover" href="#">
                                <i class="fa-brands fa-linkedin"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="team-member__info">
                        <span class="team-member__name">Ahnan Bashri</span>

                        <span class="team-member__job-title">Manager</span>
                      </div>
                    </div>
                  </Reveal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Reviews />
      </div>
      <Footer />
    </div>
  );
};

export default About;
