import React from "react";
import Header from "../components/layout/Header";
import ColorSwitcher from "../components/layout/ColorSwitcher";
import Slider from "../components/home/Slider";
import HelpYou from "../components/home/HelpYou";
import TopProducts from "../components/home/TopProducts";
import Dontmiss from "../components/home/Dontmiss";
import Reviews from "../components/home/Reviews";
import Footer from "../components/layout/Footer";

const Home = () => {
  return (
    <div>
      <Header />

      <div class="app-content">
        <Slider />
        <HelpYou />
        <TopProducts />
        <Dontmiss />
        <Reviews />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
