import React from "react";

const SelectWeek = ({ setselectedWeek, more, setmore }) => {
  return (
    <div class="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30">
      <div class="dash__pad-2">
        <h1 class="dash__h1 u-s-m-b-14">دفترچه Action</h1>
        <span class="dash__text  u-s-m-b-30">
          {more ? (
            <>
              نقطه عطف، آن لحظه جادویی ست که یک ایده، یک فکر، یک تصمیم دریچه
              جدیدی به زندگی شما باز می کند و یکباره همه چیز را دگرگون می کند.{" "}
              <br />
              من سالیان بسیار، بدنبال یافتن پاسخ سواالت خود بوده ام که چگونه یک
              انسان می تواند از هیچ به ثروت، موفقیت، شهرت، معنویت و تمام چیزهایی
              که می خواهد برسد؟ <br /> در این فرآیند از دانش بهترین اساتید جهان
              مثل جیم ران، تونی رابینز، دارن هاردی، جک کنفیلد و... بهره بردهام و
              به واسطهی مهارت تندخوانی که بیش از یک دهه با من است بالغ بر چندین
              هزار جلد کتاب و مقاله در طول این سالها خواندهام.
              <br />
              اینک متوجه رازهای پنهانی در زندگی افراد درجه یک شده ام که میتواند
              زندگی هر شخصی را متحول کند و او را به رویاهایش برساند که آن راز،
              داشتن انضباط شخصی در زندگی است.
              <br />
              واقعا هیجان زده هستم که فرصتی فراهم شده تا از دریچه این دفترچه،
              بهترین آموخته هایم را با شما به اشتراک بگذارم تا از طعم شیرین
              رسیدن به اهدافتان لذت ببرید و مهم تر از همه، بتوانید هر کاری که
              اراده میکنید در زندگی تان عملیاتی کنید.
              <br />
              استفاده از پلنری که در دست دارید به شما کمک می کند یک سیستم جادویی
              ایجاد کنید و هر روز یک درصد بهتر از قبل شوید.
              <br />َ همین ایده ساده به سبکی جالب به ما یاد می دهد چگونه با
              شناخت عادت های کوچک و مدیریت آن ها یک سبک زندگی جدید بسازیم که با
              یک درصد پیشرفت روزانه، در طول سال با قدرت عجیب اثر مرکب، ۳۷ برابر
              بهتر شویم. به امید آنکه هیچ انسانی، بخاطر ناتوانی در مدیریت اهدافش
              از رسیدن به موفقیت و رشد، محروم نماند.{" "}
              <span
                onClick={() => setmore(false)}
                className="text-[--brand] cursor-pointer"
              >
                مشاهده کمتر
              </span>
            </>
          ) : (
            <>
              نقطه عطف، آن لحظه جادویی ست که یک ایده، یک فکر، یک تصمیم دریچه
              جدیدی به زندگی شما باز می کند و یکباره همه چیز را دگرگون می کند.{" "}
              <br />
              من سالیان بسیار، بدنبال یافتن پاسخ سواالت خود بوده ام که چگونه یک
              انسان می تواند از هیچ به ثروت، موفقیت، شهرت، معنویت و تمام چیزهایی
              که می خواهد برسد؟{" "}
              <span
                onClick={() => setmore(true)}
                className="text-[--brand] cursor-pointer"
              >
                مشاهده بیشتر
              </span>
              <br />{" "}
            </>
          )}
        </span>
      </div>

      <div className="w-full grid grid-cols-2 xl:grid-cols-4 pb-5 px-5 gap-4">
        <h1 class="dash__h1 col-span-full">از اینجا شروع کن :</h1>
        {/* {Array(4).fill("").map((e, i) => (
          <button
            onClick={() => setselectedWeek(i + 1)}
            className="border rounded-md py-3 w-full font-bold text-white bg-[--brand]"
          >
            هفته {i + 1}
          </button>
        ))} */}
        <button
          onClick={() => setselectedWeek(1)}
          className="border rounded-md py-3 w-full font-bold text-white bg-[--brand]"
        >
          هفته 1
        </button>
        <button className="border rounded-md py-3 w-full font-bold opacity-50">
          هفته 2
        </button>
        <button className="border rounded-md py-3 w-full font-bold opacity-50">
          هفته 3
        </button>
        <button className="border rounded-md py-3 w-full font-bold opacity-50">
          هفته 4
        </button>
      </div>
    </div>
  );
};

export default SelectWeek;
