import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import NavDashboard from "../components/layout/NavDashboard";
import Reveal from "../components/motion/Reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../utils/httprequest";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../config";
import moment from "moment";

const Dashboard = () => {
  const [data, setData] = useState();
  const [editing, setediting] = useState(false);
  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("user"));

    // if (!user?.token) {
    //   window.location.href = window.origin + "/signup";
    // }
    // getData();
  }, []);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    await httpReauest(
      "GET",
      "/user/" + user?._id,
      {},
      { "x-access-token": user?.token }
    )
      .then((newData) => {
        setData(newData.data.data);
      })
      .catch(() => {
        localStorage.removeItem("user");
        window.location.href = window.origin + "/signup";
      });
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      address: Yup.string().required("الزامی است"),
      postalCode: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: { address: "", postalCode: "", desc: "" },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));
      await httpReauest("POST", "/user/addAddress", values, {
        "x-access-token": user?.token,
      })
        .then((data) => {
          toast.success("اطلاعات با موفقیت ثبت شد");
          formik.resetForm();
          window.location.reload();
        })
        .catch((data) => {
          toast.error(data?.response?.data?.message);
          formik.resetForm();
        });
    },
  });
  return (
    <div>
      <Header />
      <div class="app-content bg-[#eee]">
        <div class="u-s-p-y-60">
          <div class="section__content">
            <div class="dash">
              <div class="container">
                <div class="row">
                  <NavDashboard orderCount={data?.orders?.length} />
                  <div class="col-lg-9 col-md-12">
                    <Reveal>
                      <div class="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30">
                        <div class="dash__pad-2">
                          <h1 class="dash__h1 u-s-m-b-14">پروفایل</h1>

                          <span class="dash__text u-s-m-b-30">
                            از اینجا میتونی سفارشات و اطلاعاتی که به سایت میدی
                            رو مدیریت کنی
                          </span>
                          <div class="row">
                            <div class="col-12 u-s-m-b-30 ">
                              <div class="dash__box dash__box--bg-grey dash__box--shadow-2 u-h-100 rounded-[15px]">
                                {editing ? (
                                  <form
                                    onSubmit={formik.handleSubmit}
                                    class="dash__pad-3"
                                  >
                                    <h2 class="dash__h2 u-s-m-b-12">آدرس:</h2>
                                    <input
                                      class="input-text input-text--primary-style w-full"
                                      type="text"
                                      id="address"
                                      name="address"
                                      value={formik.values.address}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      placeholder=""
                                    />
                                    {formik.errors.address &&
                                      formik.touched.address && (
                                        <small
                                          className="text-danger"
                                          style={{ color: "red" }}
                                        >
                                          {formik.errors.address}
                                        </small>
                                      )}
                                    <h2 class="dash__h2 u-s-m-y-12">
                                      کد پستی:
                                    </h2>
                                    <input
                                      class="input-text input-text--primary-style"
                                      type="text"
                                      id="postalCode"
                                      name="postalCode"
                                      value={formik.values.postalCode}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      placeholder=""
                                    />
                                    {formik.errors.postalCode &&
                                      formik.touched.postalCode && (
                                        <small
                                          className="text-danger"
                                          style={{ color: "red" }}
                                        >
                                          {formik.errors.postalCode}
                                        </small>
                                      )}
                                    <h2 class="dash__h2 u-s-m-y-12">
                                      متن توضیحی: (اجباری نیست)
                                    </h2>
                                    <input
                                      class="input-text input-text--primary-style w-full"
                                      type="text"
                                      id="desc"
                                      name="desc"
                                      value={formik.values.desc}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      placeholder=""
                                    />
                                    <div class="btn text-[--brand] u-s-m-y-8">
                                      <button onClick={() => setediting(true)}>
                                        تایید
                                      </button>
                                    </div>
                                  </form>
                                ) : (
                                  <div class="dash__pad-3">
                                    <h2 class="dash__h2 u-s-m-b-12">آدرس:</h2>
                                    <span class="dash__text">
                                      {data?.address
                                        ? data?.address
                                        : "هنوز ادرسی ثبت نشده"}
                                    </span>
                                    <h2 class="dash__h2 u-s-m-y-12">
                                      کد پستی:
                                    </h2>
                                    <span class="dash__text">
                                      {data?.postalCode
                                        ? data?.postalCode
                                        : "هنوز کد پستی ثبت نشده"}
                                    </span>
                                    <h2 class="dash__h2 u-s-m-y-12">
                                      متن توضیحی: (اجباری نیست)
                                    </h2>
                                    <span class="dash__text">
                                      {data?.desc
                                        ? data?.desc
                                        : "هنوز  متنی ثبت نشده"}
                                    </span>
                                    <div class="btn text-[--brand] u-s-m-y-8">
                                      <button onClick={() => setediting(true)}>
                                        مدیریت
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Reveal>
                    <Reveal>
                      <div class="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30">
                        <div class="dash__pad-2">
                          <h1 class="dash__h1 u-s-m-b-25">اطلاعات من</h1>
                          <div class="row">
                            <div class="col-lg-4 u-s-m-b-30">
                              <h2 class="dash__h2 u-s-m-b-8">نام</h2>

                              <span class="dash__text">{data?.f_name}</span>
                            </div>
                            <div class="col-lg-4 u-s-m-b-30">
                              <h2 class="dash__h2 u-s-m-b-8">نام خانوادگی</h2>

                              <span class="dash__text">{data?.l_name}</span>
                            </div>
                            <div class="col-lg-4 u-s-m-b-30">
                              <h2 class="dash__h2 u-s-m-b-8">شماره تماس</h2>

                              <span class="dash__text">{data?.phone}</span>
                            </div>
                          </div>
                          {/* <div class="row">
                            <div class="col-lg-12">
                              <div class="u-s-m-b-16">
                                <a
                                  class="dash__custom-link btn--e-transparent-brand-b-2"
                                  href="dash-edit-profile.html"
                                >
                                  تغییر مشخصات
                                </a>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </Reveal>
                    <Reveal>
                      <div class="dash__box dash__box--shadow dash__box--bg-white dash__box--radius">
                        <h2 class="dash__h2 u-s-p-xy-20">سفارشات اخیر</h2>
                        <div class="dash__table-wrap gl-scroll">
                          <table class="dash__table">
                            <thead>
                              <tr>
                                <th>سفارش #</th>
                                <th>در تاریخ</th>
                                <th>وضعیت</th>
                                <th>قیمت</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.orders?.map((order) => {
                                return (
                                  <tr>
                                    <td>{order?._id}</td>
                                    <td>
                                      {moment(order?.createdAt).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </td>
                                    <td>{order?.status}</td>

                                    <td>
                                      <div class="dash__table-total">
                                        <span>
                                          {order?.price?.toLocaleString()}ريال
                                        </span>
                                        <div class="dash__link dash__link--brand">
                                          <Link to={"/order/" + order?._id}>
                                            مدیریت
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
