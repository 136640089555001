import React from "react";
import { useState } from "react";
import MultiRangeSlider from "../common/MultirangeSlider";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";

import SelectDate from "./SelectDate";

const DayPlanner = ({ selectedDay, selectedWeek, setselectedDay }) => {
  const [first, setFirst] = useState({ value: null, submit: false });
  const [target, setTarget] = useState({ value: null, submit: false });
  const [travel, setTravel] = useState({ value: null, submit: false });
  const [socialTime, setsocialTime] = useState({ value: null, submit: false });
  const [goodWork, setgoodWork] = useState({ value: null, submit: false });

  const [day1, setDay1] = useState([]);
  const [targets, settargets] = useState([]);
  const [notDo, setnotDo] = useState([]);
  const [punish, setpunish] = useState([]);
  const [text, settext] = useState("");
  const [textTarget, settextTarget] = useState("");
  const [textNotDo, settextNotDo] = useState("");
  const [textPunish, settextPunish] = useState("");

  const [start, startRef] = useState(null);
  const [end, endRef] = useState(null);

  return (
    <div class="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30">
      <div class="dash__pad-1 flex justify-between">
        <h1 class="dash__h2 u-s-m-b-14">
          پلنر روزانه روز {selectedDay} از هفته {selectedWeek}
        </h1>
        <button
          onClick={() => setselectedDay(null)}
          className="btn btn--e-brand-b-2 px-4"
        >
          برگشت
        </button>
      </div>
      <div className="px-4 py-5 grid grid-cols-6 md:grid-cols-6 gap-4">
        <div className=" overflow-hidden text-[11px] h-fit lg:text-[16px] text-[#333] rounded-md font-bold border-2 border-[#c5c5c5] col-span-2">
          <div className="p-2 border-b border-[#c5c5c5] text-center bg-[#d9db9a]">
            شاخص اولیه :
          </div>
          <div className="p-2 bg-white flex flex-col justify-center items-center gap-3">
            {first.submit ? (
              <>
                <>
                  <span className="text-[22px]">{first.value}</span>
                  <i
                    onClick={() =>
                      setFirst((e) => ({ value: e.value, submit: false }))
                    }
                    class="fa-solid  fa-edit text-[16px] mx-1 text-red-500"
                  ></i>{" "}
                </>
              </>
            ) : (
              <>
                <input
                  onChange={(e) =>
                    setFirst({ value: e.target.value, submit: false })
                  }
                  value={first.value}
                  type={"number"}
                  className="text-center py-2 text-[22px] input-text--primary-style w-full"
                />
                <button
                  onClick={() =>
                    setFirst((e) => ({ value: e.value, submit: true }))
                  }
                  className="rounded-full bg-green-600 px-2 py-2 mx-1"
                >
                  <i class="fa-solid  fa-check "></i>{" "}
                </button>
              </>
            )}
          </div>
        </div>
        <div className=" overflow-hidden text-[11px] h-fit lg:text-[16px] text-[#333] rounded-md font-bold border-2 border-[#c5c5c5] col-span-2">
          <div className="p-2 border-b border-[#c5c5c5] text-center bg-[#d9db9a]">
            شاخص هدف :
          </div>
          <div className="p-2 bg-white flex flex-col justify-center items-center gap-3">
            {target.submit ? (
              <>
                <>
                  <span className="text-[22px]">{target.value}</span>
                  <i
                    onClick={() =>
                      setTarget((e) => ({ value: e.value, submit: false }))
                    }
                    class="fa-solid  fa-edit text-[16px] mx-1 text-red-500"
                  ></i>{" "}
                </>
              </>
            ) : (
              <>
                <input
                  onChange={(e) =>
                    setTarget({ value: e.target.value, submit: false })
                  }
                  value={target.value}
                  type={"number"}
                  className="text-center py-2 text-[22px] input-text--primary-style w-full"
                />
                <button
                  onClick={() =>
                    setTarget((e) => ({ value: e.value, submit: true }))
                  }
                  className="rounded-full bg-green-600 px-2 py-2 mx-1"
                >
                  <i class="fa-solid  fa-check "></i>{" "}
                </button>
              </>
            )}
          </div>
        </div>
        <div className=" overflow-hidden text-[11px] h-fit lg:text-[16px] text-[#333] rounded-md font-bold border-2 border-[#c5c5c5] col-span-2">
          <div className="p-2 border-b border-[#c5c5c5] text-center bg-[#d9db9a]">
            شاخص در سفر:
          </div>
          <div className="p-2 bg-white flex flex-col justify-center items-center gap-3">
            {travel.submit ? (
              <>
                <>
                  <span className="text-[22px]">{travel.value}</span>
                  <i
                    onClick={() =>
                      setTravel((e) => ({ value: e.value, submit: false }))
                    }
                    class="fa-solid  fa-edit text-[16px] mx-1 text-red-500"
                  ></i>{" "}
                </>
              </>
            ) : (
              <>
                <input
                  onChange={(e) =>
                    setTravel({ value: e.target.value, submit: false })
                  }
                  value={travel.value}
                  type={"number"}
                  className="text-center py-2 text-[22px] input-text--primary-style w-full"
                />
                <button
                  onClick={() =>
                    setTravel((e) => ({ value: e.value, submit: true }))
                  }
                  className="rounded-full bg-green-600 px-2 py-2 mx-1"
                >
                  <i class="fa-solid  fa-check "></i>{" "}
                </button>
              </>
            )}
          </div>
        </div>
        <div className=" overflow-hidden text-[14px] col-span-full md:col-span-3 h-fit lg:text-[16px] text-[#333] rounded-md font-bold border-2 border-[#c5c5c5]">
          <div className="p-2 border-b border-[#c5c5c5] bg-[#d9db9a]">
            !! کار های بزرگ مهم :
          </div>
          <div className="p-2 bg-white ">
            {day1?.map((e, i) => {
              return (
                <div className="flex items-center justify-between border-b pb-1 gap-1 my-2 w-full">
                  <span className="flex gap-2 items-center  ">
                    <input
                      onClick={() => {
                        let updatedList = day1.map((item) => {
                          if (e.value == item.value) {
                            return { ...item, checked: !item.checked }; //gets everything that was already in item, and updates "done"
                          }
                          return item; // else return unmodified item
                        });
                        setDay1(updatedList);
                      }}
                      type="checkbox"
                      checked={e.checked}
                    />
                    {i + 1}. {e.value}
                  </span>

                  <i
                    onClick={() => {
                      const newArray = day1.filter((d) => e.value !== d.value);
                      setDay1(newArray);
                    }}
                    class="fa-solid  fa-trash text-[20px] text-red-500"
                  ></i>
                </div>
              );
            })}
            <div className="flex items-center gap-1 my-2 w-full">
              <input
                onChange={(e) => settext(e.target.value)}
                value={text}
                placeholder="اضافه کردن"
                className="input-text input-text--primary-style w-full"
              />
              <button
                onClick={() => {
                  setDay1((cur) => [...cur, { value: text, checked: false }]);
                  settext("");
                }}
                className="rounded-full bg-green-600 w-[24px] h-[24px] flex justify-center items-center"
              >
                <i class="fa-solid  fa-check "></i>{" "}
              </button>
            </div>
          </div>
        </div>

        <div className=" overflow-hidden text-[14px] col-span-full md:col-span-3 h-fit lg:text-[16px] text-[#333] rounded-md font-bold border-2 border-[#c5c5c5]">
          <div className="p-2 border-b border-[#c5c5c5] bg-[#d9db9a]">
            هدف نویسی :
          </div>
          <div className="p-2 bg-white ">
            {targets?.map((e, i) => {
              return (
                <div className="flex items-center justify-between border-b pb-1 gap-1 my-2 w-full">
                  <span className="flex gap-2 items-center text-center">
                    {e}
                  </span>

                  <i
                    onClick={() => {
                      const newArray = day1.filter((d) => e !== d);
                      settargets(newArray);
                    }}
                    class="fa-solid  fa-trash text-[20px] text-red-500"
                  ></i>
                </div>
              );
            })}
            <div className="flex items-center gap-1 my-2 w-full">
              <input
                onChange={(e) => settextTarget(e.target.value)}
                value={textTarget}
                placeholder="مثلا : من ماهانه N تومان درآمد دارم"
                className="input-text input-text--primary-style w-full"
              />
              <button
                onClick={() => {
                  settargets((cur) => [...cur, textTarget]);
                  settextTarget("");
                }}
                className="rounded-full bg-green-600 w-[24px] h-[24px] flex justify-center items-center"
              >
                <i class="fa-solid  fa-check "></i>{" "}
              </button>
            </div>
          </div>
        </div>

        <div className=" overflow-hidden text-[14px] col-span-full md:col-span-3 h-fit lg:text-[16px] text-[#333] rounded-md font-bold border-2 border-[#c5c5c5]">
          <div className="p-2 border-b border-[#c5c5c5] bg-red-300">
            <i class="fa-solid fa-xmark text-red-600 translate-y-0.5"></i>{" "}
            "نباید" امروز من :
          </div>
          <div className="p-2 bg-white ">
            {notDo?.map((e, i) => {
              return (
                <div className="flex items-center justify-between border-b pb-1 gap-1 my-2 w-full">
                  <span className="flex gap-2 items-center  ">
                    <input
                      onClick={() => {
                        let updatedList = notDo.map((item) => {
                          if (e.value == item.value) {
                            return { ...item, checked: !item.checked }; //gets everything that was already in item, and updates "done"
                          }
                          return item; // else return unmodified item
                        });
                        setnotDo(updatedList);
                      }}
                      type="checkbox"
                      checked={e.checked}
                    />
                    {i + 1}. {e.value}
                  </span>

                  <i
                    onClick={() => {
                      const newArray = notDo.filter((d) => e.value !== d.value);
                      setnotDo(newArray);
                    }}
                    class="fa-solid  fa-trash text-[20px] text-red-500"
                  ></i>
                </div>
              );
            })}
            <div className="flex items-center gap-1 my-2 w-full">
              <input
                onChange={(e) => settextNotDo(e.target.value)}
                value={textNotDo}
                placeholder="مثلا : هر 5 دقیقه فکر و خیال"
                className="input-text input-text--primary-style w-full"
              />
              <button
                onClick={() => {
                  setnotDo((cur) => [
                    ...cur,
                    { value: textNotDo, checked: false },
                  ]);
                  settextNotDo("");
                }}
                className="rounded-full bg-green-600 w-[24px] h-[24px] flex justify-center items-center"
              >
                <i class="fa-solid  fa-check "></i>{" "}
              </button>
            </div>
          </div>
        </div>

        <div className=" overflow-hidden text-[14px] col-span-full md:col-span-3 h-fit lg:text-[16px] text-[#333] rounded-md font-bold border-2 border-[#c5c5c5]">
          <div className="p-2 border-b border-[#c5c5c5] bg-red-300">
            <i class="fa-solid fa-xmark text-red-600 translate-y-0.5"></i> جریمه
            عدم رعایت "نباید" :
          </div>
          <div className="p-2 bg-white ">
            {punish?.map((e, i) => {
              return (
                <div className="flex items-center justify-between border-b pb-1 gap-1 my-2 w-full">
                  <span className="flex gap-2 items-center  ">
                    <input
                      onClick={() => {
                        let updatedList = punish.map((item) => {
                          if (e.value == item.value) {
                            return { ...item, checked: !item.checked }; //gets everything that was already in item, and updates "done"
                          }
                          return item; // else return unmodified item
                        });
                        setpunish(updatedList);
                      }}
                      type="checkbox"
                      checked={e.checked}
                    />
                    {i + 1}. {e.value}
                  </span>

                  <i
                    onClick={() => {
                      const newArray = punish.filter(
                        (d) => e.value !== d.value
                      );
                      setpunish(newArray);
                    }}
                    class="fa-solid  fa-trash text-[20px] text-red-500"
                  ></i>
                </div>
              );
            })}
            <div className="flex items-center gap-1 my-2 w-full">
              <input
                onChange={(e) => settextPunish(e.target.value)}
                value={textPunish}
                placeholder="مثلا : 500 هزار تومان"
                className="input-text input-text--primary-style w-full"
              />
              <button
                onClick={() => {
                  setpunish((cur) => [
                    ...cur,
                    { value: textPunish, checked: false },
                  ]);
                  settextPunish("");
                }}
                className="rounded-full bg-green-600 w-[24px] h-[24px] flex justify-center items-center"
              >
                <i class="fa-solid  fa-check "></i>{" "}
              </button>
            </div>
          </div>
        </div>

        <div className=" overflow-hidden text-[11px] h-fit lg:text-[16px] text-[#333] col-span-full md:col-span-3 rounded-md font-bold border-2 border-[#c5c5c5]">
          <div className="p-2 border-b border-[#c5c5c5] bg-blue-300">
            مجموع مقدار استفاده از سوشال مدیا:
          </div>
          <div className="p-2 bg-white flex justify-center items-center gap-3">
            {socialTime.submit ? (
              <>
                <>
                  <span className="text-[22px]">{socialTime.value} دقیقه</span>
                  <i
                    onClick={() =>
                      setsocialTime((e) => ({ value: e.value, submit: false }))
                    }
                    class="fa-solid  fa-edit text-[16px] mx-1 text-red-500"
                  ></i>{" "}
                </>
              </>
            ) : (
              <>
                <input
                  onChange={(e) =>
                    setsocialTime({ value: e.target.value, submit: false })
                  }
                  value={socialTime.value}
                  type={"number"}
                  className="text-center py-2 text-[22px] input-text--primary-style w-full"
                />
                دقیقه
                <button
                  onClick={() =>
                    setsocialTime((e) => ({ value: e.value, submit: true }))
                  }
                  className="rounded-full bg-green-600 px-1  text-[16px] py-1   mx-1"
                >
                  <i class="fa-solid  fa-check"></i>{" "}
                </button>
              </>
            )}
          </div>
        </div>

        <div className=" overflow-hidden text-[11px] h-fit lg:text-[16px] text-[#333] col-span-full md:col-span-3 rounded-md font-bold border-2 border-[#c5c5c5]">
          <div className="p-2 border-b border-[#c5c5c5] bg-blue-300">
            مجموع مقدار کار مفید در روز:
          </div>
          <div className="p-2 bg-white flex justify-center items-center gap-3">
            {goodWork.submit ? (
              <>
                <>
                  <span className="text-[22px]">{goodWork.value} دقیقه</span>
                  <i
                    onClick={() =>
                      setgoodWork((e) => ({ value: e.value, submit: false }))
                    }
                    class="fa-solid  fa-edit text-[16px] mx-1 text-red-500"
                  ></i>{" "}
                </>
              </>
            ) : (
              <>
                <input
                  onChange={(e) =>
                    setgoodWork({ value: e.target.value, submit: false })
                  }
                  value={goodWork.value}
                  type={"number"}
                  className="text-center py-2 text-[22px] input-text--primary-style w-full"
                />
                دقیقه
                <button
                  onClick={() =>
                    setgoodWork((e) => ({ value: e.value, submit: true }))
                  }
                  className="rounded-full bg-green-600 px-1  text-[16px] py-1   mx-1"
                >
                  <i class="fa-solid  fa-check"></i>{" "}
                </button>
              </>
            )}
          </div>
        </div>
        <div className="col-span-full">
          <h2 class="dash__h2 u-s-m-b-14 ">
            زمان بندی
            <small> (برای مشاهده زمان بندی یک کار تسک اضافه کنید) </small> :
          </h2>
          {day1?.map((e, index) => (
            <SelectDate index={index} data={e} />
          ))}
        </div>
        <button className="btn bg-green-600 py-3 col-span-full text-white mt-10 rounded-md">
          تکمیل این روز
        </button>
      </div>
    </div>
  );
};

export default DayPlanner;
