import React from "react";
import Reveal from "../motion/Reveal";

const Dontmiss = () => {
  return (
    <div class="banner-bg">
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          background: "#00000070",
        }}
      ></div>
      <div class="section__content">
        <div class="container">
        <Reveal head>
          <div class="row">
            <div class="col-lg-12">
              <div class="banner-bg__wrap">
                <div class="banner-bg__text-1">
                  <span class="u-c-white">اسم رویداد مورد نظر</span>
                </div>
                <div class="banner-bg__text-2">
                  <span class="u-c-brand">این فرصت رو از دست نده!</span>
                </div>

                <span class="banner-bg__text-block banner-bg__text-3 u-c-white">
                  متن توضیحی
                </span>

                <a
                  class="banner-bg__shop-now btn--e-secondary"
                  href="shop-side-version-2.html"
                >
                  ثبت نام
                </a>
              </div>
            </div>
          </div>
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default Dontmiss;
