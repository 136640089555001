import React, { useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import NavDashboard from "../components/layout/NavDashboard";
import Reveal from "../components/motion/Reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpReauest } from "../utils/httprequest";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../config";
import moment from "moment";
import SelectWeek from "../components/layout/SelectWeek";
import WeekPlanner from "../components/layout/WeekPlanner";

const DashPlanner = () => {
  const [data, setData] = useState();
  const [more, setmore] = useState(false);
  const [selectedWeek, setselectedWeek] = useState(false);

  useEffect(() => {
    // const user = JSON.parse(localStorage.getItem("user"));

    // if (!user?.token) {
    //   window.location.href = window.origin + "/signup";
    // }
    // getData();
  }, []);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newData = await httpReauest(
      "GET",
      "/user/" + user?._id,
      {},
      { "x-access-token": user?.token }
    );

    setData(newData.data.data);
  }

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      address: Yup.string().required("الزامی است"),
      postalCode: Yup.string().required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: { address: "", postalCode: "", desc: "" },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));
      await httpReauest("POST", "/user/addAddress", values, {
        "x-access-token": user?.token,
      })
        .then((data) => {
          toast.success("اطلاعات با موفقیت ثبت شد");
          formik.resetForm();
          window.location.reload();
        })
        .catch((data) => {
          toast.error(data?.response?.data?.message);
          formik.resetForm();
        });
    },
  });

  return (
    <div>
      <Header />
      <div class="app-content bg-[#eee]">
        <div class="u-s-p-y-60">
          <div class="section__content">
            <div class="dash">
              <div class="container">
                <div class="row">
                  <NavDashboard noOrder orderCount={data?.orders?.length} />
                  <div class="col-lg-9 col-md-12">
                    <Reveal>
                      {selectedWeek ? (
                        <WeekPlanner    setselectedWeek={setselectedWeek} selectedWeek={selectedWeek} />
                      ) : (
                        <SelectWeek
                          setmore={setmore}
                          more={more}
                          setselectedWeek={setselectedWeek}
                        />
                      )}
                    </Reveal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashPlanner;
