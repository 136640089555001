import React, { useContext, useEffect, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { CartContext } from "../App";
import { CDN_BASE_URL } from "../config";
import { toast } from "react-toastify";
import { httpReauest } from "../utils/httprequest";

const Cart = () => {
  const { cart, setCart } = useContext(CartContext);

  const [allPrice, setallPrice] = useState(0);

  useEffect(() => {
    renderData();
  }, [cart]);

  function renderData() {
    let n = 0;
    cart?.map((e) => {
      n = n + e.price * e.count;
    });
    setallPrice(n);
  }

  function handleDelete(_id) {
    const newCart = cart.filter((e) => e._id != _id);
    setCart(newCart);
  }

  function handleAddCount({
    name,
    category,
    price,
    sepcial_price,
    img,
    _id,
    count,
  }) {
    const newCart = cart.filter((e) => e._id != _id);
    const productCard = {
      name,
      category,
      price,
      sepcial_price,
      img,
      _id,
      count: count + 1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  function handleDecreseCount({
    name,
    category,
    price,
    sepcial_price,
    img,
    _id,
    count,
  }) {
    const newCart = cart.filter((e) => e._id != _id);
    const productCard = {
      name,
      category,
      price,
      sepcial_price,
      img,
      _id,
      count: count - +1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  async function handleOrder() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user?.token) {
      toast.error("برای کامل کردن خرید باید ثبت نام کتید");
      window.location.href = window.origin + "/signup";
    }

    const userAddress = await httpReauest(
      "GET",
      "/user/" + user?._id,
      {},
      { "x-access-token": user?.token }
    );
    if (userAddress.data?.data?.address && userAddress.data?.data?.postalCode) {
      let products = [];
      cart?.map((e) => {
        products.push({ productId: e?._id, count: e.count });
      });

      let n = 0;
      cart?.map((e) => {
        n = n + e.price * e.count;
      });

      await httpReauest(
        "POST",
        "/order",
        {
          userId: user?._id,
          products: products,
          price: n,
        },
        { "x-access-token": user?.token }
      )
        .then(async (e) => {
          toast.success("سفارش ثبت شد");
          await setCart([]);
          setTimeout(() => {
            window.location.href = window.origin + "/order/" + e.data.data._id;
          }, 3000);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
    } else {
      toast.error("آدرس و کد پستی برای خرید باید اضافه کنید");
      setTimeout(() => {
        window.location.href = window.origin + "/dashboard";
      }, 5000);
    }
  }

  return (
    <div>
      <Header />
      <div class="app-content bg-[#eee]">
        <div class="u-s-p-y-60">
          <div class="section__intro u-s-m-b-60">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section__text-wrap">
                    <h1 class="section__heading u-c-secondary">سبد خرید</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section__content">
            <div class="container">
              <div class="row  items-start">
                <div class="col-lg-9 col-md-12 col-sm-12  ">
                  <div class="table-responsive bg-white rounded-[15px]">
                    <table class="table-p">
                      <tbody>
                        {cart?.map((e) => {
                          return (
                            <tr>
                              <td>
                                <div class="table-p__box ">
                                  <div class="table-p__img-wrap">
                                    <img
                                      class="u-img-fluid"
                                      src={CDN_BASE_URL + e?.img}
                                      alt=""
                                    />
                                  </div>
                                  <div class="table-p__info">
                                    <span class="table-p__name">
                                      <a href="">{e?.name}</a>
                                    </span>

                                    <span class="table-p__category">
                                      <a href="">{e?.category?.name}</a>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span class="table-p__price">
                                  {e?.price * e?.count}ريال
                                </span>
                              </td>
                              <td>
                                <div class="table-p__input-counter-wrap">
                                  <div class="input-counter">
                                    <span
                                      onClick={() =>
                                        e.count > 1 &&
                                        handleDecreseCount({
                                          name: e.name,
                                          category: e.category,
                                          price: e.price,
                                          sepcial_price: e.sepcial_price,
                                          img: e.img,
                                          _id: e._id,
                                          count: e.count,
                                        })
                                      }
                                      class="input-counter__minus fa-solid fa-minus"
                                    ></span>

                                    <input
                                      class="input-counter__text input-counter--text-primary-style"
                                      type="text"
                                      value={e?.count}
                                      data-min={1}
                                      data-max="1000"
                                    />

                                    <span
                                      onClick={() =>
                                        handleAddCount({
                                          name: e.name,
                                          category: e.category,
                                          price: e.price,
                                          sepcial_price: e.sepcial_price,
                                          img: e.img,
                                          _id: e._id,
                                          count: e.count,
                                        })
                                      }
                                      class="input-counter__plus fa-solid fa-plus"
                                    ></span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="table-p__del-wrap">
                                  <a
                                    onClick={() => handleDelete(e._id)}
                                    class="fa-regular fa-trash-alt table-p__delete-link"
                                    href="#"
                                  ></a>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 u-s-m-b-30 bg-white rounded-[15px]">
                  <div class="f-cart__pad-box">
                    <div class="u-s-m-b-30">
                      <table class="f-cart__table">
                        <tbody>
                          <tr>
                            <td>هزینه ارسال</td>
                            <td>0ريال</td>
                          </tr>
                          <tr>
                            <td>جمع اغلام</td>
                            <td>{allPrice?.toLocaleString()}ريال</td>
                          </tr>
                          <tr>
                            <td>هزینه نهایی</td>
                            <td>{allPrice?.toLocaleString()}ريال</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <button
                        class="btn btn--e-brand-b-2 w-full py-2"
                        type="submit"
                        onClick={handleOrder}
                      >
                        پرداخت
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="u-s-p-b-60">
          <div class="section__content">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 u-s-m-b-30">
                  <form class="f-cart">
                    <div class="row justify-center"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cart;
