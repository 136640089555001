import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import ProductSlider from "../components/common/ProductSlider";
import Reveal from "../components/motion/Reveal";
import ProductCard from "../components/common/ProductCard";
import { httpReauest } from "../utils/httprequest";
import { useParams } from "react-router-dom";
import RateToStar from "../utils/rateToStart";
import { CartContext } from "../App";
import { toast } from "react-toastify";
const ProductDetails = () => {
  const [reviewShow, setReviewShow] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [content, setContent] = useState();
  const [rate, setrate] = useState();

  const { id } = useParams();
  const { cart, setCart } = useContext(CartContext);

  const [data, setData] = useState();
  const [data2, setData2] = useState();
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const valid = cart.find((e) => e._id == data?._id);
    if (valid) {
      setAddCart(true);
      setcountProduct(valid?.count);
    } else {
      setAddCart(false);
    }
  }, [cart]);

  async function getData() {
    const newData = await httpReauest("GET", "/product/" + id, {}, {});

    setData(newData.data.data);

    const newData2 = await httpReauest("GET", "/product", {}, {});

    setData2(newData2.data.data);

    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      setIsLogin(true);
    }
  }

  const [addCart, setAddCart] = useState();
  const [countProduct, setcountProduct] = useState(1);
  const myRef = useRef();

  async function handleAdd(params) {
    const productCard = {
      name: data.name,
      category: data.category,
      price: data.price,
      sepcial_price: data.sepcial_price,
      img: data.img,
      _id: data._id,
      count: countProduct,
    };

    setCart((current) => [...current, productCard]);
    setAddCart(true);
  }

  function handleDelete() {
    const newCart = cart.filter((e) => e._id != data?._id);
    setCart(newCart);
    setAddCart(false);
  }

  function handleAddCount() {
    setcountProduct(countProduct + 1);
    const newCart = cart.filter((e) => e._id != data?._id);
    const productCard = {
      name: data.name,
      category: data.category,
      price: data.price,
      sepcial_price: data.sepcial_price,
      img: data.img,
      _id: data._id,
      count: countProduct + 1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  function handleDecreseCount() {
    setcountProduct(countProduct - 1);
    const newCart = cart.filter((e) => e._id != data?._id);
    const productCard = {
      name: data.name,
      category: data.category,
      price: data.price,
      sepcial_price: data.sepcial_price,
      img: data.img,
      _id: data._id,
      count: countProduct - 1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));

    if (!rate || !content) {
      toast.error("امتیاز و متن هر دو الزامی است برای ثبت نظر");
    }

    await httpReauest(
      "POST",
      "/product/addReview/" + id,
      { content, rate },
      { "x-access-token": user.token }
    )
      .then((e) => {
        toast.success("نظر شما با موفقیت ثبت شد");
        setContent("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }
  return (
    <div>
      <Header />
      <div style={{ background: "#eee" }} class="app-content px-4">
        <div class="u-s-p-t-60">
          <div class="container bg-white rounded-[25px] p-4">
            <div class="row items-center">
              <div class="col-lg-5">
                <div class="pd u-s-m-b-30">
                  <ProductSlider data={data?.images} />
                </div>
              </div>
              <div class="col-lg-7">
                <div class="pd-detail">
                  <Reveal width={"fit-content"}>
                    <div>
                      <span class="pd-detail__name">{data?.name}</span>
                    </div>
                  </Reveal>

                  <div>
                    <Reveal width={"fit-content"}>
                      <div class="pd-detail__inline">
                        <span class="pd-detail__price">
                          {data?.price?.toLocaleString()}ريال
                        </span>
                      </div>
                    </Reveal>
                  </div>
                  <div class="u-s-m-b-15 u-s-m-t-5">
                    <Reveal width={"fit-content"}>
                      <div class="pd-detail__rating gl-rating-style">
                        {data?.rate ? (
                          <>
                            <RateToStar number={data?.rate} />
                            <span class="pd-detail__review u-s-m-l-4">
                              <a data-click-scroll="#view-review">
                                {data?.reviews?.length} نظر
                              </a>
                            </span>
                          </>
                        ) : (
                          <a data-click-scroll="#view-review">
                            هنوز نظر و امتیازی ثبت نشده
                          </a>
                        )}
                      </div>
                    </Reveal>
                  </div>
                  <div class="u-s-m-b-15">
                    <Reveal width={"fit-content"}>
                      <div class="pd-detail__inline">
                        {/* <span class="pd-detail__stock">200 in stock</span> */}

                        <span class="pd-detail__left">
                          {data?.balance} عدد دیگر باقی مانده
                        </span>
                      </div>
                    </Reveal>
                  </div>
                  <div class="u-s-m-b-25">
                    <Reveal width={"fit-content"}>
                      <span class="pd-detail__preview-desc">{data?.desc}</span>
                    </Reveal>
                  </div>
                  <div class="u-s-m-b-15">
                    <form class="pd-detail__form">
                      <Reveal width={"fit-content"}>
                        <div class="pd-detail-inline-2">
                          <div class="u-s-m-y-15">
                            {addCart ? (
                              <div class="input-counter">
                                <span
                                  onClick={() => {
                                    countProduct > 1
                                      ? handleDecreseCount()
                                      : countProduct == 1
                                      ? handleDelete()
                                      : setAddCart(true);
                                  }}
                                  class="input-counter__minus fa-solid fa-minus"
                                ></span>

                                <input
                                  class="input-counter__text input-counter--text-primary-style"
                                  type="text"
                                  value={countProduct}
                                  data-min={1}
                                  data-max="1000"
                                  ref={myRef}
                                />

                                <span
                                  onClick={() => handleAddCount()}
                                  class="input-counter__plus fa-solid fa-plus"
                                ></span>
                              </div>
                            ) : (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setAddCart(true);
                                  handleAdd();
                                }}
                                class="btn btn--e-brand-b-2"
                                type="submit"
                              >
                                افزودن به سبد
                              </button>
                            )}
                          </div>
                        </div>
                      </Reveal>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="u-s-m-y-60">
          <div class="container bg-white rounded-[25px] p-4">
            <div class="row">
              <div class="col-lg-12">
                <div class="pd-tab">
                  <div class="u-s-m-b-30">
                    <ul class="nav pd-tab__list">
                      <li class="nav-item">
                        <a
                          class={`nav-link ${!reviewShow && "active"}`}
                          data-toggle="tab"
                          onClick={() => setReviewShow(false)}
                        >
                          مشخصات
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class={`nav-link ${reviewShow && "active"}`}
                          id="view-review"
                          data-toggle="tab"
                          onClick={() => setReviewShow(true)}
                        >
                          نظرات
                          <span>({data?.reviews?.length})</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div class="tab-content">
                    <div
                      class={`tab-pane ${!reviewShow && "fade show active"}`}
                      id="pd-desc"
                    >
                      <div class="pd-tab__desc">
                        <div class="u-s-m-b-30">
                          <Reveal width={"fit-content"}>
                            <p>{data?.desc}</p>
                          </Reveal>
                        </div>

                        <div class="u-s-m-b-15">
                          <Reveal head>
                            <h4>ویژگی های محصول</h4>
                          </Reveal>
                        </div>
                        <div class="u-s-m-b-15">
                          <div class="pd-table gl-scroll">
                            <Reveal head>
                              <table>
                                <tbody>
                                  {data?.attributes?.map((e) => {
                                    return (
                                      <tr>
                                        <td>{e?.key}</td>
                                        <td>{e?.answar}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Reveal>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class={`tab-pane ${reviewShow && "fade show active"}`}>
                      <div class="pd-tab__rev">
                        <div class="u-s-m-b-30">
                          <div class="pd-tab__rev-score">
                            <div class="u-s-m-b-8">
                              <h2>
                                {data?.reviews?.length} نظر -{" "}
                                {data?.rate ? data.rate : "0"} (امتیاز)
                              </h2>
                            </div>
                            <div class="gl-rating-style-2 u-s-m-b-8">
                              <RateToStar number={data?.rate} />
                            </div>
                            <div class="u-s-m-b-8">
                              <h4>نظر شما برای ما خیلی مهم است</h4>
                            </div>
                          </div>
                        </div>
                        <div class="u-s-m-b-30">
                          <form class="pd-tab__rev-f1">
                            <div class="rev-f1__group">
                              <div class="u-s-m-b-15">
                                <h2>نظرات</h2>
                              </div>
                            </div>
                            <div class="rev-f1__review">
                              {data?.reviews?.map((review) => {
                                return (
                                  <div class="review-o u-s-m-b-15">
                                    <div class="review-o__info u-s-m-b-8">
                                      <span class="review-o__name">
                                        {review?.userId?.f_name} {review?.userId?.l_name}
                                      </span>
                                    </div>
                                    <div class="review-o__rating gl-rating-style u-s-m-b-8 flex">
                                      <RateToStar number={review?.rate} />

                                      <span>({review?.rate})</span>
                                    </div>
                                    <p class="review-o__text">
                                      {review?.content}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                        {isLogin ? (
                          <div class="u-s-m-b-30">
                            <form class="pd-tab__rev-f2">
                              <h2 class="u-s-m-b-25">نظری اضافه کنید</h2>

                              <div class="u-s-m-b-30">
                                <div class="rev-f2__table-wrap gl-scroll">
                                  <table class="rev-f2__table">
                                    <thead>
                                      <tr>
                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>

                                            <span>(1)</span>
                                          </div>
                                        </th>

                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>

                                            <span>(2)</span>
                                          </div>
                                        </th>

                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>

                                            <span>(3)</span>
                                          </div>
                                        </th>

                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>

                                            <span>(4)</span>
                                          </div>
                                        </th>

                                        <th>
                                          <div class="gl-rating-style-2">
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>
                                            <i class="fa-solid fa-star"></i>

                                            <span>(5)</span>
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-1"
                                              name="rating"
                                              onClick={() => setrate(1)}
                                            />
                                            {console.log(rate)}
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-1"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-3.5"
                                              name="rating"
                                              onClick={() => setrate(2)}
                                            />
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-3.5"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-4"
                                              name="rating"
                                              onClick={() => setrate(3)}
                                            />
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-4"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-4.5"
                                              name="rating"
                                              onClick={() => setrate(4)}
                                            />
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-4.5"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="radio-box">
                                            <input
                                              type="radio"
                                              id="star-5"
                                              name="rating"
                                              onClick={() => setrate(5)}
                                            />
                                            <div class="radio-box__state radio-box__state--primary">
                                              <label
                                                class="radio-box__label"
                                                for="star-5"
                                              ></label>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="rev-f2__group">
                                <div class="u-s-m-b-15">
                                  <label class="gl-label" for="reviewer-text">
                                    متن نظر *
                                  </label>
                                  <textarea
                                    class="text-area text-area--primary-style rounded-[15px]"
                                    id="reviewer-text"
                                    onChange={(e) => setContent(e.target.value)}
                                    value={content}
                                  ></textarea>
                                </div>
                              </div>
                              <div>
                                <button
                                  class="btn btn--e-brand-shadow"
                                  type="submit"
                                  onClick={handleSubmit}
                                >
                                  ارسال نظر
                                </button>
                              </div>
                            </form>
                          </div>
                        ) : (
                          <div class="u-s-m-b-30">
                            <form class="pd-tab__rev-f2">
                              <h2 class="u-s-m-b-25">
                                برای ثبت نظر باید ثبت نام کنید
                              </h2>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="u-s-p-b-90">
          <div class="section__intro u-s-m-b-46">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="section__text-wrap">
                    <h1 class="section__heading u-c-secondary u-s-m-b-12">
                      مشتریان از این محصولات هم دیدن کردند
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section__content">
            <div class="container">
              <div className="row">
                {data2?.map((e) => (
                  <ProductCard data={e} home />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetails;
