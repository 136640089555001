import React from "react";
import {
  Datepicker,
  Input,
  Page,
  setOptions,
  localeFa,
} from "@mobiscroll/react";

setOptions({
  locale: localeFa,
  theme: "ios",
  themeVariant: "light",
  rtl: true,
});
const SelectDate = ({ data, index }) => {
  console.log(data);
  return (
    <>
      <div dir="ltr" className=" col-span-full">
        <h2 dir="rtl" className="text-[16px] text-[#000] ">
          {index + 1}. {data?.value}
        </h2>
        <Datepicker
          controls={["time"]}
          select="range"
          label={"(پیش بینی)"}
          labelStyle="stacked"
          inputStyle="outline"
          placeholder="برای انتخاب زمان کلیک کنید"
          onChange={(e) => console.log(e)}
        />
        <Datepicker
          controls={["time"]}
          select="range"
          label={"(اتفاق افتاده)"}
          labelStyle="stacked"
          inputStyle="outline"
          placeholder="برای انتخاب زمان کلیک کنید"
          onChange={(e) => console.log(e)}
        />
      </div>
    </>
  );
};

export default SelectDate;
