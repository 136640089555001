import React from "react";
import Reveal from "../motion/Reveal";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import HelpAnimation from "../../lottie/help.json";
import { SrviceInfo } from "../../pages/ServiceDetails";

const HelpYou = () => {
  return (
    <div class="u-s-p-y-60">
      <div class="section__intro u-s-m-b-40">
        <div class="container">
          <div style={{ justifyContent: "center" }} class="row">
            <Reveal>
              <div class="section__text-wrap">
                <h1 class="section__heading u-c-secondary u-s-m-b-12">
                  چطوری میتونیم کمکت کنیم؟
                </h1>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
      <div class="section__content">
        <div class="container">
          <div className="row justify-between items-center">
            <div class="col-lg-6 p-0">
              {SrviceInfo.map((e) => {
                return (
                  <div class="col-12 u-s-m-b-20 ">
                    <Reveal>
                      <Link
                        to={"/service/" + e.id}
                        class="service u-h-100 hover:bg-red-200 duration-150"
                      >
                        <div class="service__icon">
                          <i class={e.ico}></i>
                        </div>
                        <div class="service__info-wrap">
                          <span class="service__info-text-1">{e.title}</span>

                          <span class="service__info-text-2">{e.subTitle}</span>
                        </div>
                      </Link>
                    </Reveal>
                  </div>
                );
              })}
            </div>
            <div class="col-lg-6 p-0">
              <Reveal>
                <Lottie animationData={HelpAnimation} loop={true} />
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpYou;
