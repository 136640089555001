import React, { useState } from "react";
import DayPlanner from "./DayPlanner";
import DayPlannerBox from "./DayPlannerBox";
import RatingPlanner from "./RatingPlanner";

const WeekPlanner = ({ selectedWeek, setselectedWeek }) => {
  const [montlyTarget, setmontlyTarget] = useState();
  const [selectedDay, setselectedDay] = useState();
  const [reward, setreward] = useState();
  const [submitReward, setsubmitReward] = useState();
  const [submitPunish, setsubmitPunish] = useState();
  const [punish, setpunish] = useState();
  const [submit, setsubmit] = useState(false);
  const [days, setDays] = useState([
    "شنبه",
    "یکشنبه",
    "دوشنبه",
    "سه شنبه",
    "چهارشنبه",
  ]);
  if (selectedDay) {
    return <DayPlanner setselectedDay={setselectedDay} selectedDay={selectedDay} selectedWeek={selectedWeek} />
  }
  return (
    <div class="dash__box dash__box--shadow dash__box--radius dash__box--bg-white u-s-m-b-30">
      <div class="dash__pad-1 flex justify-between">
        <h1 class="dash__h1 u-s-m-b-14">پلنر هفتگی هفته {selectedWeek}</h1>
        <button
          onClick={() => setselectedWeek(null)}
          className="btn btn--e-brand-b-2 px-4"
        >
          برگشت
        </button>
      </div>
      <div className="px-4 pb-5 grid md:grid-cols-2 gap-5">
        <div className="bg-[#c8ca7d] p-2 rounded-md font-bold border-2 border-[#999] col-span-full text-[14px] lg:text-[16px] text-black mb-8">
          <div className="flex items-center gap-1">
            <span className="rounded-full bg-[#867f3d] w-[18px] h-[18px] flex justify-center items-center ">
              <i class="fa-solid  fa-question "></i>{" "}
            </span>
            %25 از بودجه هدف ماهانه شما چیست؟
          </div>
          <div className="pt-2 flex gap-2 items-start justify-between">
            {submit ? (
              <>
                {montlyTarget}
                <i
                  onClick={() => setsubmit(false)}
                  class="fa-solid  fa-edit text-[20px] "
                ></i>{" "}
              </>
            ) : (
              <>
                {" "}
                <input
                  onChange={(e) => setmontlyTarget(e.target.value)}
                  value={montlyTarget}
                  placeholder="اینجا بنویس"
                  className="input-text input-text--primary-style w-full"
                />
                <button
                  onClick={() => setsubmit(true)}
                  className="rounded-full bg-green-600 w-[24px] h-[24px] flex justify-center items-center"
                >
                  <i class="fa-solid  fa-check "></i>{" "}
                </button>
              </>
            )}
          </div>
        </div>
        {days?.map((day) => (
          <DayPlannerBox name={day} />
        ))}
        <RatingPlanner
          icon={<i class="fa-solid  fa-chart-bar "></i>}
          name={"عملکرد"}
        />
        <RatingPlanner icon={<i class="fa-solid  fa-check "></i>} name={"بودجه"} />
        <div className="col-span-full overflow-hidden text-[14px] h-fit lg:text-[16px] text-[#333] rounded-md font-bold border border-[#999]">
          <div className="p-2 border-b border-[#999] bg-[#b2c3c7]">
            تعهد نامه
          </div>
          <div className="p-2 bg-white text-justify leading-8">
            من متعهد میشوم به ازای اجرای همهی اقدامکها در هفته، خودم را با یک
            <span className="">
              {" "}
              {submitReward ? (
                <>
                  <span className="bg-gray-200 px-3 py-1">{reward}</span>
                  <i
                    onClick={() => setsubmitReward(false)}
                    class="fa-solid  fa-edit text-[16px] mx-1 text-red-500"
                  ></i>{" "}
                </>
              ) : (
                <>
                  <input
                    onChange={(e) => setreward(e.target.value)}
                    value={reward}
                    placeholder="مثلا: شام Vip"
                    className="input-text input-text--primary-style w-[120px]"
                  />
                  <button
                    onClick={() => setsubmitReward(true)}
                    className="rounded-full bg-green-600 px-1 py-1 mx-1"
                  >
                    <i class="fa-solid  fa-check "></i>{" "}
                  </button>
                </>
              )}
            </span>{" "}
            تشویق میکنم و متعهد میشوم به ازای اجرا نکردن هر اقدامک، خودم را با
            مبلغ
            <span>
              {" "}
              {submitPunish ? (
                <>
                  <span className="bg-gray-200 px-3 py-1">{punish}</span>
                  <i
                    onClick={() => setsubmitPunish(false)}
                    class="fa-solid  fa-edit text-[16px] mx-1 text-red-500"
                  ></i>{" "}
                </>
              ) : (
                <>
                  <input
                    onChange={(e) => setpunish(e.target.value)}
                    value={punish}
                    placeholder="مثلا : دو میلیون"
                    className="input-text input-text--primary-style w-[120px]"
                  />
                  <button
                    onClick={() => setsubmitPunish(true)}
                    className="rounded-full bg-green-600 px-1 py-1 mx-1"
                  >
                    <i class="fa-solid  fa-check "></i>{" "}
                  </button>
                </>
              )}
            </span>{" "}
            جریمه میکنم.
          </div>
        </div>
      </div>
      <div className="px-4 pb-5">
        <h1 class="dash__h1 u-s-m-b-14 col-span-full py-3">
          پلنر روزانه برای هفته {selectedWeek} :
        </h1>
        <div className="w-full grid grid-cols-2 gap-4 lg:grid-cols-5">
          {days?.map((e) => (
            <button onClick={()=>setselectedDay(e)} className=" rounded-md p-3 w-full font-bold border-2 flex justify-between">
              {e} <i class="fa-solid  fa-angle-left "></i>{" "}
            </button>
          ))}
        </div>
        <button
          onClick={() => setselectedWeek(null)}
          className="btn bg-green-600 py-3 w-full text-white mt-10 rounded-md"
        >
          تکمیل این هفته
        </button>
      </div>
    </div>
  );
};

export default WeekPlanner;
